<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";

const props = defineProps<{
  orderId: string
}>();

const { pushSuccess, pushError } = useNotificationsEx();
const { required, sameAs, minLength, email } = customValidators();
const { isLoggedIn, user } = useUser();

const runtimeConfig = useRuntimeConfig();

const friendlyCaptcha = ref(null);

const message = reactive({
  salutation: "",
  firstname: "",
  lastname: "",
  strasse: "",
  adresszusatz: "",
  plz: "",
  ort: "",
  land: "",
  email: "",
  telefon: "",
  kdnr: "",
  datenschutz: false,
  captcha: false,
});

const rules = computed(() => ({
  salutation: {
    oneOf: (value: string) => salutationOptions.value.map((val) => val.key).includes(value),
  },
  firstname: {
    required,
    minLength: minLength(3),
  },
  lastname: {
    required,
    minLength: minLength(3),
  },
  strasse: {
    required,
    minLength: minLength(3),
  },
  adresszusatz: {},
  plz: {
    required,
  },
  ort: {
    required,
  },
  land: {},
  email: {
    required,
    email,
  },
  telefon: {},
  kdnr: {},
  datenschutz: {
    required: sameAs(true),
  },
}));

const $v = useVuelidate(rules, message);
const { triggerFriendlyCaptcha, focusOnFirstInvalidField } = useFormHelpers($v);

const salutationOptions = ref([
  {
    key: "",
    label: "Keine Angabe",
  },
  {
    key: "mx",
    label: "Divers",
  },
  {
    key: "mrs",
    label: "Frau",
  },
  {
    key: "mr",
    label: "Herr",
  },
]);

const loading = ref(false);

onMounted(async () => {
  if (isLoggedIn.value) {
    message.email = user.value?.email ?? '';
    message.salutation = user.value?.defaultBillingAddress?.salutation?.salutationKey ?? '';
    message.firstname = user.value?.firstName ?? '';
    message.lastname = user.value?.lastName ?? '';
    message.kdnr = user.value?.customerNumber?.toString() ?? '';
    message.adresszusatz = user.value?.defaultBillingAddress?.additionalAddressLine1 ?? '';
    message.strasse = user.value?.defaultBillingAddress?.street ?? '';
    message.plz = user.value?.defaultBillingAddress?.zipcode ?? '';
    message.ort = user.value?.defaultBillingAddress?.city ?? '';
    message.land = user.value?.defaultBillingAddress?.country?.name ?? '';
    message.telefon = user.value?.defaultBillingAddress?.phoneNumber ?? '';
  }
});

const onSubmit = async (e: Event) => {
  e.preventDefault();

  if(loading.value) return;
  loading.value = true;

  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid && (await triggerFriendlyCaptcha(message))) {

    try {
      const response = await $fetch(`/api/catalogorder`, {
        method: "post",
        body: message,
        headers: {
          "Content-Type": "application/json; charset: UTF-8",
          Accept: "application/json; charset: UTF-8",
        },
      });

      if (response  && response.state === "error") {
        pushError(response.statusMessage);
      }
      else 
      {
        message.email = "";
        message.kdnr = "";
        message.salutation = "";
        message.firstname = "";
        message.lastname = "";
        message.adresszusatz = "";
        message.strasse = "";
        message.plz = "";
        message.ort = "";
        message.land = "";
        message.telefon = "";
        message.datenschutz = false;
        pushSuccess("Vielen Dank für Ihre Nachricht!");
      }
    } catch (err: any) {
      pushError("Die Anfrage konnte nicht bearbeitet werden.");
    }

    $v.value.$reset();

    message.captcha = false;
    
    friendlyCaptcha.value.recreate();
  } else {
    pushError("Überprüfen Sie Ihre Eingaben, sie sind entweder fehlerhaft oder fehlen..");
    focusOnFirstInvalidField();
  }

  loading.value = false;
};

const friendlyCaptchaChange = (state) => {
  message.captcha = state !== false ? state : false;
};
</script>

<template>
  <form class="grid gap-12" method="post" @submit.prevent="onSubmit">
    <fieldset>
      <legend class="mb-4 text-h3 text-maas-typography-headline-primary">Katalog bestellen</legend>
      <p>Sie möchten unseren Katalog lieber als gedruckte Version erhalten? Bestellen Sie hier unseren Katalog zu sich nach Hause.</p>

      <div class="mt-8 grid grid-cols-2 gap-4">
        <SharedFormLabel label="Anrede">
          <SharedFormSelect
            id="salutation"
            v-model="message.salutation"
            :elems="salutationOptions"
            class="w-full"
            :valid="!$v.salutation.$invalid || !$v.salutation.$dirty"
            :error="$v.salutation?.$silentErrors?.[0]?.$message ? String($v.salutation.$silentErrors[0].$message) : ''"
            @change="$v.salutation.$touch()"
          />
        </SharedFormLabel>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <SharedFormLabel label="Vorname *" class="w-full">
          <SharedFormInput
            id="firstname"
            v-model="message.firstname"
            class="w-full"
            :valid="!$v.firstname.$invalid || !$v.firstname.$dirty"
            :error="$v.firstname?.$silentErrors?.[0]?.$message ? String($v.firstname.$silentErrors[0].$message) : ''"
            @change="$v.firstname.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Nachname *" class="w-full">
          <SharedFormInput
            id="lastname"
            v-model="message.lastname"
            class="w-full"
            :valid="!$v.lastname.$invalid || !$v.lastname.$dirty"
            :error="$v.lastname?.$silentErrors?.[0]?.$message ? String($v.lastname.$silentErrors[0].$message) : ''"
            @change="$v.lastname.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Straße *">
        <SharedFormInput
          id="strasse"
          v-model="message.strasse"
          class="w-full"
          :valid="!$v.strasse.$invalid || !$v.strasse.$dirty"
          :error="$v.strasse?.$silentErrors?.[0]?.$message ? String($v.strasse.$silentErrors[0].$message) : ''"
          @change="$v.strasse.$touch()"
        />
      </SharedFormLabel>

      <SharedFormLabel label="Adresszusatz">
        <SharedFormInput
          id="adresszusatz"
          v-model="message.adresszusatz"
          class="w-full"
          :valid="!$v.adresszusatz.$invalid || !$v.adresszusatz.$dirty"
          :error="$v.adresszusatz?.$silentErrors?.[0]?.$message ? String($v.adresszusatz.$silentErrors[0].$message) : ''"
          @change="$v.adresszusatz.$touch()"
        />
      </SharedFormLabel>

      <div class="grid grid-cols-4 gap-4">
        <SharedFormLabel label="PLZ *">
          <SharedFormInput
            id="plz"
            v-model="message.plz"
            :valid="!$v.plz.$invalid || !$v.plz.$dirty"
            :error="$v.plz?.$silentErrors?.[0]?.$message ? String($v.plz.$silentErrors[0].$message) : ''"
            @change="$v.plz.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Ort *" class="col-span-3">
          <SharedFormInput
            id="ort"
            v-model="message.ort"
            :valid="!$v.ort.$invalid || !$v.ort.$dirty"
            :error="$v.ort?.$silentErrors?.[0]?.$message ? String($v.ort.$silentErrors[0].$message) : ''"
            @change="$v.ort.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Land">
        <SharedFormInput
          id="land"
          v-model="message.land"
          :valid="!$v.land.$invalid || !$v.land.$dirty"
          :error="$v.land?.$silentErrors?.[0]?.$message ? String($v.land.$silentErrors[0].$message) : ''"
          @change="$v.land.$touch()"
        />
      </SharedFormLabel>
    </fieldset>

    <fieldset>
      <div class="grid grid-cols-2 gap-4">
        <SharedFormLabel label="E-Mail *">
          <SharedFormInput
            id="email"
            v-model="message.email"
            class="w-full"
            :valid="!$v.email.$invalid || !$v.email.$dirty"
            :error="$v.email?.$silentErrors?.[0]?.$message ? String($v.email.$silentErrors[0].$message) : ''"
            @change="$v.email.$touch()"
          />
        </SharedFormLabel>

        <SharedFormLabel label="Telefon">
          <SharedFormInput
            id="telefon"
            v-model="message.telefon"
            :valid="!$v.telefon.$invalid || !$v.telefon.$dirty"
            :error="$v.telefon?.$silentErrors?.[0]?.$message ? String($v.telefon.$silentErrors[0].$message) : ''"
            @change="$v.telefon.$touch()"
          />
        </SharedFormLabel>
      </div>

      <SharedFormLabel label="Kundennummer">
        <SharedFormInput
          id="kdnr"
          v-model="message.kdnr"
          class="w-full"
          :valid="!$v.kdnr.$invalid || !$v.kdnr.$dirty"
          :error="$v.kdnr?.$silentErrors?.[0]?.$message ? String($v.kdnr.$silentErrors[0].$message) : ''"
          @change="$v.kdnr.$touch()"
        />
      </SharedFormLabel>
    </fieldset>

    <fieldset>
      <SharedFormLabelCheck class="mt-12 w-full"
        label="Ich akzeptiere die Datenschutzbestimmungen *" 
        :error="$v.datenschutz.$invalid && $v.datenschutz.$dirty && 
                $v.datenschutz?.$silentErrors?.[0]?.$message ? String($v.datenschutz.$silentErrors[0].$message) : ''">
        <SharedFormCheckbox
          option="datenschutz"
          :selected="message.datenschutz"
          :valid="!$v.datenschutz.$invalid || !$v.datenschutz.$dirty"
          @select="message.datenschutz = !message.datenschutz"
        />
      </SharedFormLabelCheck>

      <SharedFormInput type="hidden" class="" :value="orderId" />

      <div class="flex gap-8 items-start justify-between">
        <VueFriendlyCaptcha
          class="mb-4 mt-4"
          ref="friendlyCaptcha"
          :sitekey="runtimeConfig.public.FRIENDLY_CAPTCHA_SITEKEY"
          puzzle-endpoint__="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
          language="de"
          @done="friendlyCaptchaChange"
          @error="friendlyCaptchaChange(false)"
        />

        <SharedFormButton class="mt-4" layout="primary" size="big" type="submit" :loading="loading" :disabled="loading">
          Jetzt absenden
        </SharedFormButton>
      </div>

      <p class="mt-4 text-right text-small text-maas-typography-text-grey-light">Die mit einem Stern (*) markierten Felder sind Pflichtfelder.</p>
    </fieldset>
  </form>
</template>
